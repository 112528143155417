const SVGResourceHero = () => (
  <svg
    width="576"
    height="498"
    viewBox="0 0 576 498"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="258.027" height="245.095" transform="matrix(-1 0 0 1 576 0)" fill="#202EAE" />
    <rect
      width="258.027"
      height="245.095"
      transform="matrix(-1 0 0 1 576 0)"
      fill="url(#paint0_linear_563_10918)"
    />
    <path d="M576 0L413.947 41.4345V306.051L576 246.43V0Z" fill="#FC5C03" />
    <path d="M576 0L413.947 41.4345V306.051L576 246.43V0Z" fill="url(#paint1_linear_563_10918)" />
    <path d="M35.5614 39.5293L373.042 133.528L37.1347 229.433L35.5614 39.5293Z" fill="#FFC21A" />
    <path
      d="M35.5614 39.5293L373.042 133.528L37.1347 229.433L35.5614 39.5293Z"
      fill="url(#paint2_linear_563_10918)"
    />
    <path
      d="M373.046 133.468L385.799 184.939C384.307 186.669 379.667 190.108 373.046 190.021C366.426 189.935 362.419 187.246 361.243 185.912L373.046 133.468Z"
      fill="url(#paint3_linear_563_10918)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M378.387 155.014L373.048 133.468L368.217 154.933C369.921 155.261 371.697 155.434 373.522 155.434C375.19 155.434 376.818 155.289 378.387 155.014Z"
      fill="url(#paint4_linear_563_10918)"
    />
    <path
      d="M385.799 185.335C385.799 183.629 384.416 182.246 382.709 182.246H380.703C378.997 182.246 377.614 183.629 377.614 185.335V473.415H385.799V185.335Z"
      fill="url(#paint5_linear_563_10918)"
    />
    <path
      d="M377.614 185.335C377.614 183.629 376.231 182.246 374.525 182.246H372.518C370.812 182.246 369.429 183.629 369.429 185.335V473.415H377.614V185.335Z"
      fill="url(#paint6_linear_563_10918)"
    />
    <path
      d="M369.429 185.335C369.429 183.629 368.046 182.246 366.34 182.246H364.333C362.627 182.246 361.244 183.629 361.244 185.335V473.415H369.429V185.335Z"
      fill="url(#paint7_linear_563_10918)"
    />
    <ellipse
      rx="33.04"
      ry="95.2693"
      transform="matrix(-1 0 0 1 33.2012 134.799)"
      fill="url(#paint8_linear_563_10918)"
    />
    <ellipse cx="373.522" cy="494.584" rx="12.2775" ry="2.83146" fill="#FC5C03" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M361.266 446.048H361.244V494.544H385.799L385.799 446.048H385.777C385.391 447.532 380.051 448.707 373.521 448.707C366.991 448.707 361.652 447.532 361.266 446.048Z"
      fill="#FC5C03"
    />
    <defs>
      <linearGradient
        id="paint0_linear_563_10918"
        x1="6.46221"
        y1="31.0403"
        x2="247.503"
        y2="31.0403"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#070F58" />
        <stop offset="1" stopColor="#202EAE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_563_10918"
        x1="516.33"
        y1="186.455"
        x2="398.412"
        y2="200.567"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC6B1A" stopOpacity="0" />
        <stop offset="1" stopColor="#E05406" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_563_10918"
        x1="127.601"
        y1="201.487"
        x2="117.227"
        y2="104.278"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9900" />
        <stop offset="1" stopColor="#FFCA39" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_563_10918"
        x1="382.481"
        y1="172.972"
        x2="362.398"
        y2="172.142"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C9C9D5" />
        <stop offset="1" stopColor="#F3F3F1" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_563_10918"
        x1="376.455"
        y1="154.739"
        x2="371.863"
        y2="154.62"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0520833" stopColor="#FABC14" />
        <stop offset="1" stopColor="#FFCA3A" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_563_10918"
        x1="384.159"
        y1="182.246"
        x2="377.614"
        y2="182.246"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCA39" />
        <stop offset="1" stopColor="#F9BA0F" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_563_10918"
        x1="375.974"
        y1="182.246"
        x2="369.429"
        y2="182.246"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCA39" />
        <stop offset="1" stopColor="#F9BA0F" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_563_10918"
        x1="367.789"
        y1="182.246"
        x2="361.244"
        y2="182.246"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCA39" />
        <stop offset="1" stopColor="#F9BA0F" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_563_10918"
        x1="9.27901"
        y1="69.9346"
        x2="52.8599"
        y2="165.204"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC6B1A" />
        <stop offset="1" stopColor="#F5C54C" />
      </linearGradient>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGResourceHero;
