import { useMemo } from 'react';

import { GridContainer } from 'components/Container';
import { PreviewCard } from './PreviewCard';

import type { FC } from 'react';
import type { Size } from 'styled/types';
import type { PreviewCardProps } from './types';

interface PreviewCardsProps {
  entries: PreviewCardProps[];
  columns?: 2 | 3 | 4 | 5 | 6;
  gap?: Size;
  verticalAlign?: 'top' | 'center' | 'bottom';
}

export const PreviewCards: FC<PreviewCardsProps> = ({ entries, verticalAlign, columns, gap }) => {
  const cards = useMemo(
    () =>
      entries.map(entry => (
        <PreviewCard
          key={entry.title}
          categories={entry.categories}
          subtitle={entry.subtitle}
          urlSlug={entry.urlSlug}
          title={entry.title}
          description={entry.description}
          image={entry.image}
        />
      )),
    [entries]
  );

  return (
    <GridContainer columns={columns || 3} gap={gap || 5} verticalAlign={verticalAlign}>
      {cards}
    </GridContainer>
  );
};
