import { StaticImage } from 'gatsby-plugin-image';
import PartnerImage from 'images/help/cards/become-a-partner';
import AffiliateImage from 'images/help/cards/become-an-affiliate';
import { LargeCardContent, SmallCardContent } from 'components/Help';

import type { PreviewCardProps } from 'components/PreviewCards/types';
import type { ImageCardProps } from 'components/ImageCard';

export const helpCards: ImageCardProps[] = [
  {
    title: 'Getting started',
    content: <SmallCardContent>How to set up your portfolio in Sharesight.</SmallCardContent>,
    background: 'orangeHighlight',
    image: (
      <StaticImage
        src="../images/help/cards/lines-arrow.png"
        alt="Graphic image of a rectangle with three lines"
      />
    ),
    to: '/help/getting-started/',
    localize: true,
  },
  {
    title: 'Reports',
    content: (
      <SmallCardContent>Guides on how to use Sharesight’s reports and features.</SmallCardContent>
    ),
    background: 'yellowHighlight',
    image: (
      <StaticImage
        src="../images/help/cards/report.png"
        alt="Graphic image of three question marks"
      />
    ),
    to: '/help/reports/',
    localize: true,
  },
  {
    title: 'Corporate actions',
    content: <SmallCardContent>Setting up corporate action automations.</SmallCardContent>,
    background: 'primaryHighlight',
    image: (
      <StaticImage
        src="../images/help/cards/magnifying-glass.png"
        alt="Graphic image of three vertical rectangles"
      />
    ),
    to: '/help/corporate-actions/',
    localize: true,
  },
  {
    title: 'Asset support',
    content: (
      <SmallCardContent>How to add different asset classes into Sharesight.</SmallCardContent>
    ),
    background: 'yellowHighlight',
    image: (
      <StaticImage src="../images/help/cards/lines.png" alt="Graphic image of two chat icons" />
    ),
    to: '/help/asset-support/',
    localize: true,
  },
  {
    title: 'Tax',
    content: <SmallCardContent>Step-by-step guides on how to use tax features.</SmallCardContent>,
    background: 'primaryHighlight',
    image: (
      <StaticImage
        src="../images/help/cards/calculator.png"
        alt="Graphic image of three circles with shadows"
      />
    ),
    to: '/help/tax/',
    localize: true,
  },
  {
    title: 'Accounts',
    content: (
      <SmallCardContent>
        Account settings, portfolio preferences and customisations.
      </SmallCardContent>
    ),
    background: 'orangeHighlight',
    image: (
      <StaticImage src="../images/help/cards/gears.png" alt="Graphic image of a location icon" />
    ),
    to: '/help/account/',
    localize: true,
  },
];

export const ctaCards: ImageCardProps[] = [
  {
    title: 'For business',
    content: (
      <LargeCardContent>
        Help guides for business on how to set up client portfolios in Sharesight.
      </LargeCardContent>
    ),
    to: '/help/sharesight-pro/',
    background: 'navyBlueHighlight',
    image: <PartnerImage />,
    showButton: true,
    localize: true,
  },
  {
    title: 'Become an affiliate',
    content: (
      <LargeCardContent>
        Help your followers become better investors and earn up to 50% commission per referral.
      </LargeCardContent>
    ),
    to: '/affiliates/',
    background: 'orangeHighlight',
    image: <AffiliateImage />,
    showButton: true,
  },
];

export const videoTutorial: PreviewCardProps[] = [
  {
    title: '3 ways to add your investments in Sharesight',
    urlSlug: 'https://www.youtube.com/watch?v=OrvyYejF30Q',
    image: {
      gatsbyImageData: null,
      staticImage: (
        <StaticImage
          src="../images/help/video-tutorials/3-ways-to-add-investments.jpeg"
          alt="3 ways to add your investments in Sharesight banner"
        />
      ),
    },
  },
  {
    title: 'Importing trading history using the File Importer',
    urlSlug: 'https://www.youtube.com/watch?v=w8j_MBg7lzQ',
    image: {
      gatsbyImageData: null,
      staticImage: (
        <StaticImage
          src="../images/help/video-tutorials/importing-trade-history.jpeg"
          alt="Importing trading history using the File Importer banner"
        />
      ),
    },
  },
  {
    title: 'Tour of portfolio overview page',
    urlSlug: 'https://www.youtube.com/watch?v=jNWt90yHdBU',
    image: {
      gatsbyImageData: null,
      staticImage: (
        <StaticImage
          src="../images/help/video-tutorials/portfolio-overview-page.jpeg"
          alt="Tour of portfolio over page banner"
        />
      ),
    },
  },
];
