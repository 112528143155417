/*
 * The top half of this container is navyBlue,
 * to give the appearance of the Help hero image overlaying the Cards in the pricing page
 */

import styled from 'styled-components';

import { Container } from 'components/Container';

import type { ReactNode } from 'react';

const StyledContainer = styled(Container)`
  background: ${props =>
    `linear-gradient(${props.theme.colors.navyBlue} 78%, ${props.theme.colors.white} 15%) no-repeat`};

  color: ${props => props.theme.colors.white};

  ${({ theme }) => theme.breakpoints.down('md')} {
    background: ${props =>
      `linear-gradient(${props.theme.colors.navyBlue} 88%, ${props.theme.colors.white} 10%) no-repeat`};
  }
`;

export const HelpOverlayContainer = ({ children }: { children: ReactNode }) => (
  <StyledContainer padding={0} bottomPadding={15}>
    {children}
  </StyledContainer>
);
