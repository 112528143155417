const SVGBecomeAnAffiliate = () => (
  <svg
    width="462"
    height="272"
    viewBox="0 0 462 272"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M167.631 158.812L278.209 221.096" stroke="#FC5C03" />
    <path d="M167.631 157.87L327.71 137.317" stroke="#FC5C03" />
    <path d="M167.361 158.273L368.029 202.94" stroke="#FC5C03" />
    <path d="M167.092 157.466L383.117 81.9884" stroke="#FC5C03" />
    <g style={{ mixBlendMode: 'multiply' }}>
      <circle cx="78.8887" cy="158.812" r="46" fill="#FEBE9A" />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <circle r="83.2899" transform="matrix(1 0 0 -1 167.093 154.789)" fill="#FEBE9A" />
    </g>
    <path
      d="M396.286 81.2076C396.286 88.0628 390.722 93.6203 383.857 93.6203C376.993 93.6203 371.429 88.0628 371.429 81.2076C371.429 74.3524 376.993 68.795 383.857 68.795C390.722 68.795 396.286 74.3524 396.286 81.2076Z"
      fill="#FC5C03"
      stroke="#FC5C03"
      strokeWidth="0.183679"
    />
    <path
      d="M349.155 134.322C349.155 141.177 343.591 146.735 336.727 146.735C329.862 146.735 324.298 141.177 324.298 134.322C324.298 127.467 329.862 121.909 336.727 121.909C343.591 121.909 349.155 127.467 349.155 134.322Z"
      fill="#FC5C03"
      stroke="#FC5C03"
      strokeWidth="0.183679"
    />
    <path
      d="M421.311 159.827C421.311 166.682 415.747 172.239 408.883 172.239C402.019 172.239 396.454 166.682 396.454 159.827C396.454 152.972 402.019 147.414 408.883 147.414C415.747 147.414 421.311 152.972 421.311 159.827Z"
      fill="#FC5C03"
      stroke="#FC5C03"
      strokeWidth="0.183679"
    />
    <path
      d="M376.426 202.458C376.426 209.313 370.861 214.87 363.997 214.87C357.133 214.87 351.568 209.313 351.568 202.458C351.568 195.602 357.133 190.045 363.997 190.045C370.861 190.045 376.426 195.602 376.426 202.458Z"
      fill="#FC5C03"
      stroke="#FC5C03"
      strokeWidth="0.183679"
    />
    <path
      d="M296.257 226.306C296.257 233.161 290.692 238.719 283.828 238.719C276.964 238.719 271.399 233.161 271.399 226.306C271.399 219.451 276.964 213.894 283.828 213.894C290.692 213.894 296.257 219.451 296.257 226.306Z"
      fill="#FC5C03"
      stroke="#FC5C03"
      strokeWidth="0.183679"
    />
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGBecomeAnAffiliate;
