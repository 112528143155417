const SVGBecomeAPartner = () => (
  <svg
    width="462"
    height="285"
    viewBox="0 0 462 285"
    fill="none"
    style={{ width: '100%' }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1532_11141)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.099 258.315C243.036 258.315 290.003 211.347 290.003 153.41C290.003 95.4729 243.036 48.5056 185.099 48.5056C127.162 48.5056 80.1943 95.4729 80.1943 153.41C80.1943 211.347 127.162 258.315 185.099 258.315ZM185.099 216.863C220.143 216.863 248.552 188.454 248.552 153.41C248.552 118.366 220.143 89.9571 185.099 89.9571C150.054 89.9571 121.646 118.366 121.646 153.41C121.646 188.454 150.054 216.863 185.099 216.863Z"
        fill="#FC6C1C"
      />
      <mask
        id="mask0_1532_11141"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="46"
        y="129"
        width="322"
        height="173"
      >
        <path
          d="M367.961 233.827L69.9356 301.341L46.2149 196.63L344.24 129.117L367.961 233.827Z"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask0_1532_11141)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.66 72.1616C73.7435 108.757 66.9975 174.835 103.593 219.752C140.188 264.669 206.266 271.414 251.183 234.819C296.1 198.224 302.846 132.146 266.25 87.2291C229.655 42.3124 163.577 35.5665 118.66 72.1616ZM144.842 104.297C117.674 126.432 113.593 166.401 135.728 193.569C157.863 220.738 197.832 224.818 225 202.683C252.169 180.548 256.249 140.579 234.114 113.411C211.979 86.2424 172.011 82.162 144.842 104.297Z"
          fill="url(#paint0_linear_1532_11141)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M275.109 258.314C333.046 258.314 380.013 211.346 380.013 153.409C380.013 95.4722 333.046 48.5048 275.109 48.5048C217.171 48.5048 170.204 95.4722 170.204 153.409C170.204 211.346 217.171 258.314 275.109 258.314ZM275.108 216.863C310.152 216.863 338.561 188.454 338.561 153.41C338.561 118.366 310.152 89.9568 275.108 89.9568C240.064 89.9568 211.655 118.366 211.655 153.41C211.655 188.454 240.064 216.863 275.108 216.863Z"
        fill="#FEBE9A"
      />
      <mask
        id="mask1_1532_11141"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="75"
        y="-26"
        width="315"
        height="259"
      >
        <path
          d="M75.5723 142.916L330.613 -25.4036L389.752 64.2047L134.711 232.524L75.5723 142.916Z"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask1_1532_11141)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M365.879 206.227C394.996 156.138 377.995 91.929 327.906 62.812C277.817 33.6951 213.607 50.6963 184.49 100.785C155.373 150.874 172.375 215.084 222.464 244.201C272.553 273.318 336.762 256.316 365.879 206.227ZM330.043 185.396C347.654 155.099 337.371 116.261 307.074 98.6492C276.777 81.0374 237.939 91.3209 220.327 121.618C202.715 151.915 212.999 190.753 243.296 208.365C273.593 225.977 312.431 215.693 330.043 185.396Z"
          fill="url(#paint1_linear_1532_11141)"
        />
      </g>
      <mask
        id="mask2_1532_11141"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="157"
        y="37"
        width="200"
        height="85"
      >
        <path d="M157.737 37.213H356.832V121.029H157.737V37.213Z" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask2_1532_11141)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M185.1 258.293C243.037 258.293 290.004 211.326 290.004 153.388C290.004 95.4512 243.037 48.4839 185.1 48.4839C127.163 48.4839 80.1953 95.4512 80.1953 153.388C80.1953 211.326 127.163 258.293 185.1 258.293ZM185.1 216.842C220.144 216.842 248.553 188.433 248.553 153.389C248.553 118.344 220.144 89.9356 185.1 89.9356C150.055 89.9356 121.647 118.344 121.647 153.389C121.647 188.433 150.055 216.842 185.1 216.842Z"
          fill="#FC6C1C"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1532_11141"
        x1="270.656"
        y1="208.539"
        x2="142.809"
        y2="248.243"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0143974" stopColor="#FC6B1A" />
        <stop offset="0.333333" stopColor="#B84505" />
        <stop offset="1" stopColor="#FC6B1A" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1532_11141"
        x1="175.542"
        y1="132.246"
        x2="281.16"
        y2="49.9893"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.197917" stopColor="#FDAF84" stopOpacity="0" />
        <stop offset="0.526042" stopColor="#FF6F1F" />
        <stop offset="0.90625" stopColor="#F38F57" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_1532_11141">
        <rect width="354" height="262.362" fill="white" transform="translate(54.3887 21.7861)" />
      </clipPath>
    </defs>
  </svg>
);

// eslint-disable-next-line import/no-default-export
export default SVGBecomeAPartner;
