import HeroImage from 'images/help/help-hero';
import { Container, HeroContainer, Section, SectionImage } from 'components/Container';
import { ImageCards } from 'components/ImageCard';
import { Layout } from 'components/Layout';
import { PreviewCards } from 'components/PreviewCards';
import { HelpOverlayContainer } from 'components/Help';
import { Seo } from 'components/Seo';
import { ctaCards, helpCards, videoTutorial } from 'page-data/help';
import { Box } from 'components/Box';
import type { FC } from 'react';

export const Help: FC = () => (
  <Layout hideFooterCta>
    <Seo />

    <HelpOverlayContainer>
      <HeroContainer background="transparent" wrapMobile="hard">
        <Section>
          <Box paddingBottom={15}>
            <h1>Help</h1>
            <p>Get started with our step-by-step help guides or video tutorials.</p>
          </Box>
        </Section>
        <SectionImage image={<HeroImage />} boxShadow={false} borderRadius={false} />
      </HeroContainer>
    </HelpOverlayContainer>
    <Container padding={1} bottomPadding={15} background="white" skipColoration>
      <ImageCards cards={helpCards} />
    </Container>
    <Container>
      <Section>
        <h2>Video tutorials</h2>
        <PreviewCards entries={videoTutorial} verticalAlign="top" />
      </Section>
    </Container>
    <Container background="navyBlue">
      <ImageCards cards={ctaCards} gap={15} />
    </Container>
  </Layout>
);

export default Help;
